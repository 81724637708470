import { Folder, File } from 'lucide-react';


const FileExplorer = ({ structure, startFile, currentPath }) => {
    const isRoot = currentPath === '' || currentPath === '/' || (currentPath.split('/').length === 1 && currentPath.split('.') === 2);
    // console.log(`currentPath: '${currentPath}'`);

    const renderTree = (tree, path = '') => {
      return Object.entries(tree).map(([key, value]) => {
        const fullPath = `${path}${key}`;
        // remove trailing slash and trailing point if it's only one point (not two)
        const currentPathCleaned = currentPath.replace(/\/$/, '').replace(/\/\.$/, ''); 
        const isCurrentPath = fullPath === currentPathCleaned;

        // Check if this is the current folder (exact match or one dot)
        const isCurrentFolder = fullPath === currentPathCleaned || 
          (currentPathCleaned === `${fullPath}/.` && value !== null);

        // Check if this is a parent folder of the current path
        const isParentFolder = currentPathCleaned.startsWith(`${fullPath}/`) && 
          currentPathCleaned.split('/').length > fullPath.split('/').length;


        const isStartFile = fullPath === startFile;
        

        if (value === null) {
          // Render files
          return (
              <div key={fullPath} className={`flex items-center file_in_folder ${isCurrentPath ? 'current_path' : ''} ${isStartFile ? 'startFile' : ''}`}>
                  <File size={16} className="mr-1" />
                  {key}
              </div>
          );
        } else {
          // Recursively render subfolders
          return (
            <div key={fullPath} className={`${isCurrentFolder ? 'current_folder_items' : ''}`}>
              <div className={`flex items-center ${isCurrentPath ? 'current_path' : ''} ${isCurrentFolder ? 'current_folder' : ''} ${isParentFolder ? 'parent_folder' : ''}`}>
                  <Folder size={16} className="mr-1" />
                  {key}
              </div>
              <div className="ml-4">
                  {renderTree(value, `${fullPath}/`)}
              </div>
            </div>
          );
        }
        });
    };

  return <div className={`${isRoot ? 'root_folder' : ''} border p-4 rounded-md`}>{renderTree(structure)}</div>;
};

  
export default FileExplorer;