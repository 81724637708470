const levels = [
    {
      startFile: 'index.html',
      targetFile: 'info.html',
      structure: {
        'index.html': null,
        'info.html': null
      }
    },
    {
      startFile: 'index.html',
      targetFile: 'honden/samson.png',
      structure: {
        'index.html': null,
        'honden': {
          'samson.png': null,
        }
      }
    },
    {
      startFile: 'website/pagina.html',
      targetFile: 'index.html',
      structure: {
        'index.html': null,
        'website': {
          'pagina.html': null,
        }
      }
    },
    {
      startFile: 'website/fruit/appels.html',
      targetFile: 'index.html',
      structure: {
        'index.html': null,
        'website': {
          'fruit': {
            'appels.html': null,
          }
        }
      }
    },
    {
        startFile: 'hip.html',
        targetFile: 'map/map/hop.html',
        structure: {
            'hip.html': null,
            'map': {
                'hap.html': null,
                'map': {
                    'hop.html': null,
                }
            }
        }
    },
    {
        startFile: 'map/map/hop.html',
        targetFile: 'map/hap.html',
        structure: {
            'hip.html': null,
            'map': {
                'hap.html': null,
                'map': {
                    'hop.html': null,
                }
            }
        }
    },
    {
      startFile: 'html/project.html',
      targetFile: 'js/project.js',
      structure: {
        'index.html': null,
        'html': {
          'project.html': null,
        },
        'js': {
          'project.js': null,
        }
      }
    }
  ];
  
export default levels;