import React, { useState, useEffect } from 'react';
import { Check } from 'lucide-react';
import levels from './levels';
import FileExplorer from './FileExplorer';
import { Fireworks } from 'fireworks-js'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCYShkl4i0YYZusTiphqWX533Uv7YLaZYs",
  authDomain: "logicgame-0.firebaseapp.com",
  databaseURL: "https://logicgame-0.firebaseio.com",
  projectId: "logicgame-0",
  storageBucket: "logicgame-0.appspot.com",
  messagingSenderId: "475398915438",
  appId: "1:475398915438:web:c97f2242c3b2479603ed7f",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
logEvent(analytics, 'page_view'); 


const LevelTimeline = ({ currentLevel, completedLevels, maxReachedLevel, onLevelSelect }) => {
  return (
    <div className="flex justify-between mb-8 bg-gray-100 p-4 rounded-lg">
      {levels.map((_, index) => (
        <div
          key={index}
          className={`flex flex-col items-center cursor-pointer ${
            index > maxReachedLevel ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          onClick={() => index <= maxReachedLevel && onLevelSelect(index)}
        >
          <div
            className={`w-8 h-8 rounded-full flex items-center justify-center mb-2 ${
              index === currentLevel
                ? 'bg-blue-500 text-white'
                : completedLevels.includes(index)
                ? 'bg-green-500 text-white'
                : 'bg-gray-300'
            }`}
          >
            {completedLevels.includes(index) ? (
              <Check size={16} />
            ) : (
              index + 1
            )}
          </div>
          <span className="text-sm">Level {index + 1}</span>
        </div>
      ))}
    </div>
  );
};


const App = () => {
  const [currentLevel, setCurrentLevel] = useState(0);
  const [userInput, setUserInput] = useState('');
  const [currentPath, setCurrentPath] = useState('');
  const [message, setMessage] = useState('');
  const [completedLevels, setCompletedLevels] = useState([]);
  const [maxReachedLevel, setMaxReachedLevel] = useState(0);


  // Reset user input, current path and message when changing levels
  useEffect(() => {
    setUserInput('');
    setCurrentPath(levels[currentLevel].startFile);         // Set the current path to the start path to show the correct path in the file explorer
    console.log(`Set current path to: ${levels[currentLevel].startFile}`);
    setMessage('');
  }, [currentLevel]);

  const handleAndSetCurrentPath = (input) => {
    const parts = input.split('/');
    let path = levels[currentLevel].startFile.split('/').slice(0, -1).join('/');
    for (const part of parts) {
      if (part === '..') {
        path = path.split('/').slice(0, -1).join('/');
      } else if (part !== '.') {
        path = path ? `${path}/${part}` : part;
      }
    }
    setCurrentPath(path);
  }

  // Reset message when user input changes
  const handleInputChange = (e) => {
    setUserInput(e.target.value);
    const input = e.target.value;
    handleAndSetCurrentPath(input);

    if (input.includes('href')) {
      setMessage('Je hebt het woord "href" gebruikt, dat hoeft niet. Keep it simple!');
    } else if (input.includes('<a')) {
      setMessage('Je gebruikt een a-tag, dat hoeft niet. Keep it simple!');
    } else {
      setMessage(''); // Reset message
    }
  };

  const handleSubmit = () => {
    const startParts = levels[currentLevel].startFile.split('/');
    const targetParts = levels[currentLevel].targetFile.split('/');
    
    // If the user input starts with a slash, remove it
    const userInp = userInput.startsWith('/') ? userInput.slice(1) : userInput;
    const userParts = userInp.split('/');
    
    let currentPath = [...startParts];
    currentPath.pop(); // Remove the start file (last part of the start path)
    
    let depth = currentPath.length; // Track how many directories deep we are
    let invalidTraversal = false;   // Track invalid 'overshooting' with '../'
  
    // Process the user input path
    for (const part of userParts) {
      if (part === '..') {
        if (depth === 0) {
          // Invalid: Trying to go above the root directory
          invalidTraversal = true;
          break;
        }
        currentPath.pop();
        depth--;
      } else if (part !== '.') {
        currentPath.push(part);
        depth++;
      }
    }
  
    const userFinalPath = currentPath.join('/');
    const targetFinalPath = targetParts.join('/');
    
    // Check if user traversed too far or if paths match
    if (invalidTraversal || userFinalPath !== targetFinalPath) {
      setMessage('Dat is niet het juiste pad. Probeer het opnieuw!');
    } else {
      setMessage('Goed gedaan! Je hebt het juiste pad gevonden.');
      if (!completedLevels.includes(currentLevel)) {
        setCompletedLevels([...completedLevels, currentLevel]);
      }
      if (currentLevel === maxReachedLevel && currentLevel < levels.length - 1) {
        setMaxReachedLevel(currentLevel + 1);
      }
      if (currentLevel < levels.length - 1) {
        setTimeout(() => {
          setCurrentLevel(currentLevel + 1);
        }, 2000);
      } else {
        setMessage('Gefeliciteerd! Je hebt alle levels voltooid.');
        const container = document.getElementById('fireworks');
        const fireworks = new Fireworks(container, {particles: 100, intensity: 50, traceSpeed: 5, acceleration: 1.01 });
        fireworks.start();
      }
    }
  };
  

  const handleLevelSelect = (level) => {
    if (level <= maxReachedLevel) {
      setCurrentLevel(level);
    }
  };

  // Handle pressing "Enter" to submit
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Leer Relatieve Paden</h1>
      <LevelTimeline
        currentLevel={currentLevel}
        completedLevels={completedLevels}
        maxReachedLevel={maxReachedLevel}
        onLevelSelect={handleLevelSelect}
      />
      <div className="mb-4">
        <p>Level {currentLevel + 1}: 
        Je zit in <strong>{levels[currentLevel].startFile.split("/").pop()}</strong>. 
        Maak een link naar <strong>{levels[currentLevel].targetFile.split("/").pop()}</strong></p>
      </div>
      <div className="flex mb-4">
        <FileExplorer 
          structure={levels[currentLevel].structure} 
          startFile={levels[currentLevel].startFile}
          currentPath={currentPath}
        />
        <div className="ml-4 flex-grow">
          <input
            type="text"
            value={userInput}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder="Typ hier je relatieve pad..."
            className="w-full p-2 mr-1 border rounded"
            style={{ maxWidth: '450px' }}
          />
          <button onClick={handleSubmit} className="mt-2 bg-blue-500 text-white p-2 rounded">
            Indienen
          </button>
        </div>
      </div>
      {message && <p data-testid="message" id="boodschap" className="mt-2">{message}</p>}
      <div id="fireworks" style={{ position: 'fixed', top: 0, left: '4%', width: '92%', height: '100%', zIndex: -1 }}></div>
    </div>
  );
};

export default App;